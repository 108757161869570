import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import { getContent } from './aiApi.js';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [inputValue, setInputValue] = useState('');
  const [uiState, setUiState] = useState({
    hasLoaded: false,
    isLoading: false,
    showContent: false,
    answerStatus: null
  });
  const [previousInputValue, setPreviousInputValue] = useState('');

  const handleContentFetch = useCallback(async (value) => {
    setPreviousInputValue(inputValue);

    setUiState(prev => ({
      ...prev,
      hasLoaded: false,
      isLoading: true,
      showContent: false,
      answerStatus: null
    }));

    try {
      const combinedValue = `${previousInputValue}, ${value}`.trim();
      await getContent(combinedValue);
      setUiState(prev => ({
        ...prev,
        hasLoaded: true,
        isLoading: false,
        showContent: true
      }));
    } catch (error) {
      console.error('Failed to fetch content:', error);
      setUiState(prev => ({
        ...prev,
        isLoading: false,
        hasLoaded: true
      }));
    }
  }, [inputValue, previousInputValue]);

  useEffect(() => {
    const getUrlParameter = (value) => {
      const params = new URLSearchParams(window.location.search);
      return params.get(value);
    };

    const valueFromUrl = getUrlParameter('value');
    const truncatedValue = valueFromUrl && valueFromUrl.length > 80 ? valueFromUrl.substring(0, 80) : valueFromUrl;

    if (truncatedValue && !uiState.hasLoaded && !inputValue) {
      handleContentFetch(truncatedValue);
    }
  }, [uiState.hasLoaded, inputValue, handleContentFetch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleContentFetch(inputValue);
  };

  const correctQuestion = (e) => {
    e.preventDefault();
    const selectedOption = document.querySelector('.question input[type="radio"]:checked');
    setUiState(prev => ({
      ...prev,
      answerStatus: selectedOption?.value === "true" ? 'correct' : 'incorrect'
    }));
  };

  const answerCorrect = () => (
    <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
      This is correct!
    </Alert>
  );

  const answerinCorrect = () => (
    <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error">
      This is incorrect.
    </Alert>
  );

  return (
    <div className="App">
      <header className="App-header">
        <br />
        <h3 className="title">What would you like to learn about?</h3>
        <form className="inquireInput" onSubmit={handleSubmit} style={{ width: '50%', textAlign: 'center' }}>
          <input
            className="inputString"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{
              width: '100%',
              height: '3rem',
              padding: '0.5rem 1rem',
              fontSize: '1.125rem',
              backgroundColor: 'white',
              color: 'black',
              border: '2px solid #ccc',
              borderRadius: '0.25rem',
              margin: '0 auto'
            }}
          />
          <br />
          <button
            style={{
              textAlign: 'center',
              padding: '0.75rem 2rem',
              fontSize: '1.125rem',
              marginTop: '1.5rem',
              minWidth: '8rem',
              borderRadius: '0.25rem',
              border: 'none',
              cursor: 'pointer'
            }}
            type="submit"
          >Learn!</button>
        </form>
        <div className="container">
          <div style={{ position: 'relative', minHeight: '50px' }}>
            {uiState.isLoading && (
              <div className="loader" style={{
                marginTop: '1.25rem',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1000
              }}></div>
            )}
          </div>
          <div className="box" style={{ display: uiState.showContent ? 'flex' : 'none', flexDirection: 'row', flexWrap: 'wrap' }}>
            <div className="border rounded p-3 mb-3" style={{ flex: '0 0 100%', maxWidth: '100%' }}>
              <div
                className="row p-3 center box-row box-cell image"
                style={{
                  display: uiState.showContent ? 'block' : 'none'
                }}
              ></div>
            </div>
            <div className="border rounded p-3 mb-3" style={{ flex: '0 0 100%', maxWidth: '100%' }}>
              <div
                className="row p-3 center box-row box-cell pageContent"
                style={{
                  display: uiState.showContent ? 'block' : 'none'
                }}
              ></div>
            </div>
            <div className="border rounded p-3 mb-3" style={{ flex: '0 0 100%', maxWidth: '100%' }}>
              <form onSubmit={correctQuestion}
                style={{ maxHeight: '80vh', marginBottom: '1.5rem', display: uiState.showContent ? 'flex' : 'none', alignItems: 'center', maxWidth: '100%', flexDirection: 'column' }}>
                <pre
                  className="question"
                  style={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    maxwidth: '100%',
                    overflow: 'auto',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal'
                  }}></pre>
                <button
                  className="questionButton"
                  style={{
                    display: uiState.hasLoaded ? 'inline-block' : 'none',
                    textAlign: 'center',
                    padding: '0.25rem 1rem',
                    fontSize: '1rem',
                    width: '100%',
                    maxWidth: '200px'
                  }}
                  type="submit"
                >Answer</button>
                <div style={{ marginTop: '0.15rem', textAlign: 'center', width: '100%', overflow: 'visible' }}>
                  {uiState.answerStatus === 'correct' && answerCorrect()}
                  {uiState.answerStatus === 'incorrect' && answerinCorrect()}
                </div>
              </form>
            </div>
          </div>
          <br />
        </div>

      </header>
    </div>
  );
}
export default App;
