const OPENAI_KEY = 'Bearer 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'
export async function getContent(inputValue) {
    await clearPageContent();
    await clearInputField();
    const sanitizedInput = await sanitizeInputText(inputValue);
    return Promise.all([
        getImage(sanitizedInput),
        getPageInfo(sanitizedInput),
        getQuestion(sanitizedInput)
    ]);
}

async function sanitizeInputText(inputValue) {
    try {
        const response = await makeOpenAIRequest('chat/completions', checkSanityCall(inputValue));
        const content = response.choices[0].message.content;
        return content;
    } catch (error) {
        console.error('getPageInfo failed:', error);
    }
}

function checkSanityCall(originInput) {
    return {
        "model": "gpt-4o",
        "max_tokens": 100,
        "messages": [{
            "role": "user",
            "content": `check the string, ${originInput}, for grammar and spelling errors and correct them as reasonably possible and return this as content with nothing else added, if it looks unreasonable or random then state this, then make a coherent and reasonable suggestion.`
        }]
    };
}

function createPageCall(originInput) {
    return {
        "model": "gpt-4o",
        "max_tokens": 500,
        "messages": [{
            "role": "user",
            "content": `250 word paragraph only (with no additional comments) for ${originInput} (with this as main title) which is organized with subject headings that are in <h2> html elements, and has html anchors for key concept words embedded in the text that have the href attribute set to href=/?value= the innertext of the anchor and concatenated with ${originInput}.`
        }]
    };
}

function generateQuestion(originInput) {
    return {
        "model": "gpt-4o",
        "max_tokens": 500,
        "messages": [{
            "role": "user",
            "content": `create html code only, with no other information, that is a trivia question about ${originInput}, that uses a left justified input element with classname=triviaQuestion, of type radio, that includes 4 options with one correct answer that has parameter value set to boolean value true.`
        }]
    };
}

function createImageCall(originInput) {
    return {
        "model": "dall-e-3",
        "prompt": `a image that best represents ${originInput}`
    };
}

// Create a utility function for making API calls
async function makeOpenAIRequest(endpoint, payload) {
    try {
        const response = await fetch(`https://api.openai.com/v1/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': OPENAI_KEY
            },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error(`Request failed with status ${response.status}`);
        }

        return await response.json();
    } catch (error) {
        console.error('API request failed:', error);
        throw error;
    }
}

// Utility function to safely append content to an element
function appendToElement(className, content, isImage = false) {
    const element = document.getElementsByClassName(className)[0];
    if (!element) {
        console.error(`Element with class "${className}" not found`);
        return;
    }

    if (isImage) {
        const img = document.createElement('img');
        img.style.margin = '1rem 0';
        img.src = content;
        img.alt = "Generated image";
        element.appendChild(img);
    } else {
        const div = document.createElement('div');
        div.innerHTML = content.replace(/\n+/gm, "").replace(/```html+/gm, "").replace(/```/gm, "");
        element.appendChild(div);
    }
}

// Refactored API functions
export async function getPageInfo(inputValue) {
    try {
        const response = await makeOpenAIRequest('chat/completions', createPageCall(inputValue));
        const content = response.choices[0].message.content;
        appendToElement('pageContent', content);
    } catch (error) {
        console.error('getPageInfo failed:', error);
    }
}

export async function getQuestion(inputValue) {
    try {
        const response = await makeOpenAIRequest('chat/completions', generateQuestion(inputValue));
        const content = response.choices[0].message.content;
        appendToElement('question', content);
    } catch (error) {
        console.error('getQuestion failed:', error);
    }
}

export async function getImage(inputValue) {
    try {
        const response = await makeOpenAIRequest('images/generations', createImageCall(inputValue));
        response.data.forEach(item => {
            appendToElement('image', item.url, true);
        });
    } catch (error) {
        console.error('getImage failed:', error);
    }
}

export async function clearPageContent() {
    return new Promise((resolve) => {
        ['pageContent', 'image', 'question'].forEach(elementClass => {
            const element = document.getElementsByClassName(elementClass)[0];
            if (element) {
                element.innerHTML = '';
            }
        });
        resolve();
    });
}

export async function clearInputField() {
    return new Promise((resolve) => {
        const inputElement = document.getElementsByClassName('inputString')[0];
        if (inputElement) {
            inputElement.value = '';
        }
        resolve();
    });
}
